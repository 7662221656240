const ADD_TO_CART = 'add_to_cart';
const ADD_TO_FAVORITES = 'add_to_favorites';
const APPLY_COUPON = 'apply_coupon';
const APPLY_FILTER = 'apply_filter';
const CAPTURE_EMAIL_CLICKED = 'capture_email_clicked';
const CLICK_CITIES_RELATED_CITY = 'click_cities_related_city';
const CLICK_DLP_POPULAR_HOTEL = 'click_dlp_popular_hotel';
const COMPLETE_BILLING_DETAILS = 'complete_billing_detail';
const COMPLETE_CONTACT_DETAILS = 'complete_contact_details';
const COMPLETE_GUEST_DETAILS = 'complete_guest_details';
const CONTINUE_AS_GUEST = 'continue_as_guest';
const FORGOT_PASSWORD_CLICK = 'Forgot_Password_Click';
const IM_FLEXIBLE_BUTTON_CLICKED = 'im_flexible_button_clicked';
const INVALID_CHECKOUT_FIELDS = 'invalid_checkout_fields';
const LEAVE_CHECKOUT = 'leave_checkout';
const LOG_OUT = 'log_out';
const LOGIN = 'login';
const LOGIN_BUTTON_CLICK = 'Login_Button_Click';
const OAUTH_BUTTON_CLICK = 'OAuth_Button_Click';
const PROCEED_TO_CHECKOUT = 'proceed_to_checkout';
const REMOVE_FROM_CART = 'remove_from_cart';
const REMOVE_FROM_FAVORITES = 'remove_from_favorites';
const RESET_LINK_BUTTON_CLIK = 'Reset_Link_Button_Click';
const SEARCH = 'search';
const SELECT_CALENDAR_PRODUCT_FILTER = 'select_calendar_product_filter';
const SELECT_HOTEL_CARD = 'select_hotel_card';
const SELECT_PRODUCT_CARD = 'select_product_card';
const SELECT_SEARCH_DATE = 'select_search_date';
const SELECT_SEARCH_LOCATION = 'select_search_location';
const SELECT_SEARCH_ON_VIEW_DLP = 'select_date_on_view_dlp';
const SHARE_BOOKING = 'share_booking';
const SHOW_EXPIRED_BOOKING_WINDOW = 'show_expired_booking_window';
const SHOW_PRODUCT_NO_LONGER_AVAILABLE = 'show_product_no_longer_available';
const SIGN_UP = 'sign_up';
const SIGN_UP_INITIALIZED = 'sign_up_initiated';
const SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED = 'skinny_banner_app_download_cta_clicked';
const SPA_SECTION_CLICKED = 'spa_section_clicked';
const SPA_SECTION_CLICKED_SRP = 'spa_section_clicked_srp';
const START_RESET_PASSWORD = 'start_reset_password';
const START_SELECT_LOCATION = 'start_select_location';
const SUMMER_WEEKDAYS_SECTION_CLICKED = 'summer_weekdays_section_clicked';
const SURVEY_SKIPPED = 'survey_skipped';
const SURVEY_SUBMITTED = 'survey_submitted';
const SWIPE_HOMEPAGE_PHOTO = 'swipe_homepage_photo';
const UNCHECK_TEXT_OFFERS = 'uncheck_text_offers';
const VIEW_BOOKING = 'view_booking';
const VIEW_DETAIL_LISTING_PAGE = 'view_detail_listing_page';
const VIEW_DLP_REVIEWS = 'view_dlp_reviews';
const VIEW_HOMEPAGE = 'view_homepage';
const VIEW_PRODUCT_CARD = 'view_product_card';
const VIEW_SEARCH_RESULTS_PAGE = 'view_search_results_page';
const VIEW_SHOPPING_CART = 'view_shopping_cart';

export {
  ADD_TO_CART,
  ADD_TO_FAVORITES,
  APPLY_COUPON,
  APPLY_FILTER,
  CAPTURE_EMAIL_CLICKED,
  CLICK_CITIES_RELATED_CITY,
  CLICK_DLP_POPULAR_HOTEL,
  COMPLETE_BILLING_DETAILS,
  COMPLETE_CONTACT_DETAILS,
  COMPLETE_GUEST_DETAILS,
  CONTINUE_AS_GUEST,
  FORGOT_PASSWORD_CLICK,
  IM_FLEXIBLE_BUTTON_CLICKED,
  INVALID_CHECKOUT_FIELDS,
  LEAVE_CHECKOUT,
  LOG_OUT,
  LOGIN,
  LOGIN_BUTTON_CLICK,
  OAUTH_BUTTON_CLICK,
  PROCEED_TO_CHECKOUT,
  REMOVE_FROM_CART,
  REMOVE_FROM_FAVORITES,
  RESET_LINK_BUTTON_CLIK,
  SEARCH,
  SELECT_CALENDAR_PRODUCT_FILTER,
  SELECT_HOTEL_CARD,
  SELECT_PRODUCT_CARD,
  SELECT_SEARCH_DATE,
  SELECT_SEARCH_LOCATION,
  SELECT_SEARCH_ON_VIEW_DLP,
  SHARE_BOOKING,
  SHOW_EXPIRED_BOOKING_WINDOW,
  SHOW_PRODUCT_NO_LONGER_AVAILABLE,
  SIGN_UP_INITIALIZED,
  SIGN_UP,
  SKINNY_BANNER_APP_DOWNLOAD_CTA_CLICKED,
  SPA_SECTION_CLICKED_SRP,
  SPA_SECTION_CLICKED,
  START_RESET_PASSWORD,
  START_SELECT_LOCATION,
  SUMMER_WEEKDAYS_SECTION_CLICKED,
  SURVEY_SKIPPED,
  SURVEY_SUBMITTED,
  SWIPE_HOMEPAGE_PHOTO,
  UNCHECK_TEXT_OFFERS,
  VIEW_BOOKING,
  VIEW_DETAIL_LISTING_PAGE,
  VIEW_DLP_REVIEWS,
  VIEW_HOMEPAGE,
  VIEW_PRODUCT_CARD,
  VIEW_SEARCH_RESULTS_PAGE,
  VIEW_SHOPPING_CART,
};
