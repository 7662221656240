import React from 'react';
import { marketingEmailSignupId } from '@constants/marketing';
import { useEvents } from '@events/EventsProvider';
import { CAPTURE_EMAIL_CLICKED } from '@constants/amplitudeEvents';

type Props = {
  onClick: () => void;
};

export default function CaptureEmailNavMenuItem({ onClick }: Props) {
  const { track } = useEvents();

  const handleClick = () => {
    track(CAPTURE_EMAIL_CLICKED, { source: 'navbar' });
    onClick();
  };

  return (
    <button
      className="text-xl text-left font-rp-pn-semi-bold cursor-pointer hover:underline bg-savings-gradient text-transparent bg-clip-text"
      type="button"
      id={marketingEmailSignupId}
      onClick={handleClick}
    >
      Unlock Savings
    </button>
  );
}
