/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { checkIfStringIsValidEmail } from '@helpers/checkPassword';
import { useCookies } from 'react-cookie';
import CheckoutFormInput from '@components/common/CheckoutForm/CheckoutFormInput';
import useIsMobile from '@hooks/useIsMobile';
import useAuthentication from '@hooks/useAuthentication';
import { RESET_LINK_BUTTON_CLIK, START_RESET_PASSWORD } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import FormInput from '../common/FormInput';
import goBackIcon from '../../assets/images/chevron-left.svg';
import crossMarkRedIcon from '../../assets/images/cross-icon-red.png';
import ButtonPrimary from '../common/ButtonPrimary';
import EmailConfirmation from './EmailConfirmation';

export default function ForgotPassword(props: any) {
  const [username, setUserName] = useState('');
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);
  const [isSendLinkButtonClicked, setIsSendLinkButtonClicked] = useState(false);
  const [isForgotPasswordRequestSent, setIsForgotPasswordRequestSent] = useState(false);
  const {
    setIsForgotPasswordClicked,
    setIsLoginButtonClicked,
    isCheckout,
    setHideHeader,
    onCloseClick,
  } = props;

  const [isForgotPasswordFocused, setIsForgotPasswordFocused] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { requestResetPasswordEmail } = useAuthentication();
  const [, setCookie] = useCookies(['userNamePassResetReq']);
  const isMobile = useIsMobile();
  const { track } = useEvents();

  const handleSubmit = async (e: any) => {
    track(RESET_LINK_BUTTON_CLIK, {});
    if (!isDisabled) {
      e.preventDefault();
      setIsSendLinkButtonClicked(true);
      setIsDisabled(true);
      const passResetReq = await requestResetPasswordEmail(username);
      if (passResetReq !== 'error') {
        setCookie('userNamePassResetReq', username, { path: '/' });
        setIsForgotPasswordRequestSent(true);
        setShowInvalidEmailMessage(false);

        track(START_RESET_PASSWORD, {});

        if (onCloseClick) {
          onCloseClick({
            show: true,
            success: true,
            message: 'Check your email to reset your password',
          });
        }
      } else {
        setShowInvalidEmailMessage(true);
        setIsDisabled(false);
      }
    }
  };

  const onBackToLoginClick = () => {
    setIsForgotPasswordClicked(false);
    if (setHideHeader) {
      setHideHeader(false);
    }
  };

  const renderBackToLoginButton = () => (
    <button
      type="button"
      className="text-rp-primary-dark text-sm pt-6"
      onClick={onBackToLoginClick}
    >
      Back to Login
    </button>
  );

  useEffect(() => {
    if (isSendLinkButtonClicked && !checkIfStringIsValidEmail(username)) {
      setShowInvalidEmailMessage(true);
      setTimeout(() => {
        setIsSendLinkButtonClicked(false);
      }, 2000);
    } else {
      setShowInvalidEmailMessage(false);
    }
  }, [username, isSendLinkButtonClicked]);

  useEffect(() => {
    if (isForgotPasswordFocused && isDisabled) {
      setIsDisabled(false);
    }
  }, [isDisabled, isForgotPasswordFocused]);

  return isForgotPasswordRequestSent ? (
    <EmailConfirmation setIsLoginButtonClicked={setIsLoginButtonClicked} isCheckout={isCheckout} />
  ) : (
    <div>
      {isCheckout ? (
        <div className="flex flex-col">
          {!isMobile && (
            <p className="text-white text-32 pr-10 font-rp-gelica-light w-3/4 leading-tight">
              Forgot your password?
            </p>
          )}
          {!isMobile && (
            <p className="text-white text-sm pr-10 font-rp-pn-light pt-4">
              We&apos;ll confirm your account by sending you link to the email address below
            </p>
          )}
          <div className="mt-8 px-4 d:px-0">
            <CheckoutFormInput
              type="email"
              id="login-email-address"
              label="Email Address"
              value={username}
              setValue={setUserName}
              autoComplete="off"
              error={showInvalidEmailMessage}
            />
            {showInvalidEmailMessage && (
              <div className="ml-2 flex items-center mt-0.5">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <p className={`ml-2 text-xs ${isMobile ? 'text-red-600' : 'text-white'}`}>
                  There is no user registered with that email address
                </p>
              </div>
            )}
            <div className="pt-7">
              <ButtonPrimary isDisabled={isDisabled} onClick={(e) => handleSubmit(e)}>
                Send Password Link
              </ButtonPrimary>
            </div>
            {renderBackToLoginButton()}
          </div>
        </div>
      ) : (
        <div className="relative bg-white w-auto pt-7 px-5 pb-9 border rounded-xl shadow-lg mx-3 my-10 d:max-w-152 d:my-8 d:mx-auto">
          <button type="button" onClick={() => setIsForgotPasswordClicked(false)}>
            <Image src={goBackIcon} alt="close-icon" />
          </button>
          <div className="relative top-2 w-full font-rp-pn-regular text-rp-primary-black">
            <div className="text-2xl font-rp-pn-semi-bold mb-5 d:text-3xl d:mb-4">
              Forgot Password?
            </div>
            <div className="mb-8">
              Enter your email address below, and if an account exists, we’ll send you a link to
              reset your password.
            </div>
            <FormInput
              type="email"
              id="login_id"
              label="Email Address"
              value={username}
              setValue={setUserName}
              autoComplete="email"
              error={showInvalidEmailMessage}
              focused={isForgotPasswordFocused}
              setFocused={setIsForgotPasswordFocused}
              handleSubmit={handleSubmit}
            />
            {showInvalidEmailMessage && (
              <div className="ml-2">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <a className="ml-2 text-xs text-red-600">
                  There is no user registered with that email address
                </a>
              </div>
            )}
            <div className="relative top-4">
              <ButtonPrimary isDisabled={isDisabled} onClick={(e) => handleSubmit(e)}>
                Send Link
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
