import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { checkIfStringIsValidEmail } from '@helpers/checkPassword';
import { addLocalStorageHotelsToFavoritesUponLogin } from '@helpers/addHotelToFavorites';
import { passwordInvalid } from '@events/Auth';
import { isArray } from 'lodash';
import { isAValidURLPath } from '@helpers/validation';
import useIsMobile from '@hooks/useIsMobile';
import CheckoutFormInput from '@components/common/CheckoutForm/CheckoutFormInput';
import useAuthentication from '@hooks/useAuthentication';
import { accessAllFavorites } from '@utils/services';
import { UserWithFavorites } from '@customTypes/user';
import { useEvents } from '@events/EventsProvider';
import { FORGOT_PASSWORD_CLICK, LOGIN_BUTTON_CLICK } from '@constants/amplitudeEvents';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import googleIcon from '../../assets/images/google-icon.svg';
import appleIcon from '../../assets/images/apple-icon.png';
import FormInput from '../common/FormInput';
import ButtonPrimary from '../common/ButtonPrimary';
import ButtonLogin from '../common/ButtonLogin';
import crossMarkRedIcon from '../../assets/images/close-red-bold.svg';

type Props = {
  isLoginButtonClicked: boolean;
  setIsForgotPasswordClicked: Dispatch<SetStateAction<boolean>>;
  setIsLoginButtonClicked: Dispatch<SetStateAction<boolean>>;
  isCheckout?: boolean;
  setHideHeader?: Dispatch<SetStateAction<boolean>>;
};

const defaultProps = {
  isCheckout: false,
  setHideHeader: () => null,
};

export default function Login({
  isLoginButtonClicked,
  setIsForgotPasswordClicked,
  setIsLoginButtonClicked,
  isCheckout = false,
  setHideHeader,
}: Props) {
  const router = useRouter();
  const { track } = useEvents();
  const { handleProviderLogin, loginUser } = useAuthentication();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);
  const [showInvalidPasswordMessage, setShowInvalidPasswordMessage] = useState(false);

  const [isUserNameFocused, setIsUserNameFocused] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);

  const primaryUnderlineLinkClasses = 'font-rp-pn-semi-bold text-rp-primary-light underline ml-2';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['userInformation']);
  const { getItem } = useSessionStorage();

  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const iterableEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);
  const isMobile = useIsMobile();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    track(LOGIN_BUTTON_CLICK, {});
    const cartId = getItem(sessionStorageKeys.CART_ID);
    const loginPayload = {
      username,
      password,
    };
    const userInfo = await loginUser(loginPayload, cartId);

    if (!!userInfo && userInfo !== 'error') {
      const userWithFavorites: UserWithFavorites = {
        ...userInfo,
        favorites: Object.keys(await accessAllFavorites(userInfo)) || [],
      };

      setCookie('userInformation', userWithFavorites, { path: '/' });

      await addLocalStorageHotelsToFavoritesUponLogin(userWithFavorites, getItem);

      if (window.Iterable) {
        await window.Iterable.updateUserEmail(
          userInfo.email,
          iterableEmail || `${sessionID}@placeholder.email`,
          userInfo,
        );
      }

      if (setIsLoginButtonClicked) {
        setIsLoginButtonClicked(false);
      }

      if (window.location.href.includes('sign-in')) {
        router.push('/users/bookings');
      }

      if (window.location.href.includes('my-favorites')) {
        window.location.reload();
      }
    } else {
      setShowInvalidEmailMessage(true);
      setShowInvalidPasswordMessage(true);
      passwordInvalid();
    }
  };

  const showHotelPartnerLoginLink = () => {
    if (
      typeof window !== 'undefined' &&
      window.location.href.includes('/products/checkout-confirmation')
    ) {
      return false;
    }

    return true;
  };

  const onForgotPasswordClick = () => {
    track(FORGOT_PASSWORD_CLICK, {});
    setIsForgotPasswordClicked(true);
    if (setHideHeader) {
      setHideHeader(true);
    }
  };

  useEffect(() => {
    if (window.location.href.includes('sign-in') && cookies.userInformation?.email) {
      if (router?.query?.referrer) {
        const referrer = isArray(router?.query?.referrer)
          ? router?.query?.referrer[0]
          : router?.query?.referrer;

        if (isAValidURLPath(referrer)) {
          router.push(referrer);
        }
      }
    }
  }, [cookies.userInformation, router]);

  useEffect(() => {
    if (isUserNameFocused && showInvalidEmailMessage) {
      setShowInvalidEmailMessage(false);
    }
    if (isPasswordFocused && showInvalidPasswordMessage) {
      setShowInvalidPasswordMessage(false);
    }
  }, [isPasswordFocused, isUserNameFocused, showInvalidEmailMessage, showInvalidPasswordMessage]);

  return (
    <>
      {/* Email and Password login */}
      <div className="text-left">
        {isCheckout ? (
          <>
            <CheckoutFormInput
              type="email"
              id="login_id"
              label="Email Address"
              value={username}
              setValue={setUserName}
              autoComplete="email"
              error={showInvalidEmailMessage && !checkIfStringIsValidEmail(username)}
            />
            {showInvalidEmailMessage && !checkIfStringIsValidEmail(username) && (
              <div className="ml-2 flex items-center mt-0.5">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <a className={`ml-2 text-xs ${isMobile ? 'text-red-600' : 'text-white'}`}>
                  The email address is invalid
                </a>
              </div>
            )}
          </>
        ) : (
          <>
            <FormInput
              type="email"
              id="login_id"
              label="Email Address"
              value={username}
              setValue={setUserName}
              autoComplete="email"
              error={showInvalidEmailMessage && !checkIfStringIsValidEmail(username)}
              focused={isUserNameFocused}
              setFocused={setIsUserNameFocused}
              handleSubmit={handleSubmit}
            />
            {showInvalidEmailMessage && !checkIfStringIsValidEmail(username) && (
              <div className="ml-2 flex mb-2">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <a className="ml-2 text-xs text-red-600">The email address is invalid</a>
              </div>
            )}
          </>
        )}
        {isCheckout ? (
          <div className="mt-2.5">
            <CheckoutFormInput
              type="password"
              id="login_pw"
              label="Password"
              value={password}
              setValue={setPassword}
              autoComplete="off"
              error={showInvalidPasswordMessage}
              onFocus={() => setShowInvalidPasswordMessage(false)}
            />
            {showInvalidPasswordMessage && (
              <div className="ml-2 flex items-center mt-0.5">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <p className={`ml-2 text-xs ${isMobile ? 'text-red-600' : 'text-white'}`}>
                  The password is invalid
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            <FormInput
              type="password"
              id="login_pw"
              label="Password"
              value={password}
              setValue={setPassword}
              autoComplete="off"
              error={showInvalidPasswordMessage}
              focused={isPasswordFocused}
              setFocused={setIsPasswordFocused}
              handleSubmit={handleSubmit}
            />
            {showInvalidPasswordMessage && (
              <div className="ml-2 flex">
                <Image src={crossMarkRedIcon} alt="x-icon" height="10" width="10" />
                <p className="ml-2 text-xs text-red-600 whitespace-nowrap">
                  The password is invalid
                </p>
              </div>
            )}
          </>
        )}
        <div className="mt-3.5 mb-8">
          <button
            onClick={onForgotPasswordClick}
            type="button"
            className="font-rp-pn-semi-bold text-rp-primary-light ml-2"
          >
            Forgot Password?
          </button>
        </div>
        {isCheckout && !isMobile && (
          <div className="text-12 text-white mb-5 ml-1">
            By proceeding you agree to our{' '}
            <a href="/terms-conditions" className="underline" target="_blank">
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a href="/privacy-policy" className="underline" target="_blank">
              Privacy Policy
            </a>
            .
          </div>
        )}
        <ButtonPrimary onClick={(e) => handleSubmit(e)}>Login</ButtonPrimary>
      </div>
      {!isLoginButtonClicked && (
        <>
          {/* Divider */}
          <div
            className={`relative flex justify-center items-center ${isCheckout ? 'my-7' : 'my-5'}`}
          >
            <div className="w-full h-px top-1/2 bg-rp-gray-divider" />
            <div
              className={`${
                isCheckout && !isMobile ? 'bg-rp-primary-new text-white' : 'bg-white text-rp-gray'
              }  absolute px-4 font-rp-pn-semi-bold`}
            >
              or
            </div>
          </div>
          {/* Alternative login options */}
          <div className="text-center">
            <ButtonLogin
              onClick={() => handleProviderLogin('facebook')}
              classes={`${isCheckout && !isMobile ? 'bg-white' : ''} flex`}
            >
              <div className="absolute left-10 mt-1.5">
                <Image src={facebookIcon} alt="facebook-icon" width="25" height="25" />
              </div>
              <span className="ml-5">Log in with Facebook</span>
            </ButtonLogin>
            <ButtonLogin
              onClick={() => handleProviderLogin('google')}
              classes={`${isCheckout && !isMobile ? 'bg-white' : ''}`}
            >
              <div className="absolute left-10 mt-1.5">
                <Image src={googleIcon} alt="google-icon" width="25" height="25" />
              </div>
              <span className="ml-5">Log in with Google</span>
            </ButtonLogin>
            {!isCheckout && (
              <div className="font-rp-pn-light mt-3 ml-3">
                <div className="flex justify-center">
                  <div className="mr-3 flex-shrink-0">
                    <Image src={appleIcon} alt="apple-icon" width="15" height="15" />
                  </div>

                  <div className="mb-4">
                    To sign in with Apple, use the
                    <span className="font-rp-pn-regular">
                      <a className="ml-1" href="https://itunes.apple.com/app/id1557539492">
                        ResortPass
                      </a>
                    </span>
                    {' app.'}
                  </div>
                </div>

                <div className="mb-2 text-left">
                  By signing up for an account, you agree to our{' '}
                  <a href="/terms-conditions" target="_blank" className="underline">
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a href="/privacy-policy" target="_blank" className="underline">
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            )}
            {showHotelPartnerLoginLink() ? (
              <>
                {/* Divider */}
                <div className="relative flex justify-center items-center my-5">
                  <div className="w-full h-px absolute top-1/2 bg-rp-gray-divider" />
                </div>
                <div className="font-rp-pn-light mt-5">
                  <p className="d:inline-block">Are you a hotel partner?</p>
                  <span className={primaryUnderlineLinkClasses}>
                    <a
                      className="ml-1"
                      href={`${process.env.NEXT_PUBLIC_RAILS_APP_URL}/hotel_users/sign_in`}
                    >
                      Log in here
                    </a>
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

Login.defaultProps = defaultProps;
